import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=6b3bac62&scoped=true"
import script from "./SearchBar.vue?vue&type=script&lang=js"
export * from "./SearchBar.vue?vue&type=script&lang=js"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=6b3bac62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b3bac62",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./SnackController.vue?vue&type=template&id=5140e47d&scoped=true"
import script from "./SnackController.vue?vue&type=script&lang=js"
export * from "./SnackController.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5140e47d",
  null
  
)

export default component.exports
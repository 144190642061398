import { render, staticRenderFns } from "./StackPanel.vue?vue&type=template&id=474394de&scoped=true"
import script from "./StackPanel.vue?vue&type=script&lang=js"
export * from "./StackPanel.vue?vue&type=script&lang=js"
import style0 from "./StackPanel.vue?vue&type=style&index=0&id=474394de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474394de",
  null
  
)

export default component.exports
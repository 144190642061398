import { render, staticRenderFns } from "./OrdersHistory.vue?vue&type=template&id=74a3a90b&scoped=true"
import script from "./OrdersHistory.vue?vue&type=script&lang=js"
export * from "./OrdersHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a3a90b",
  null
  
)

export default component.exports